<template>
  <section class="mx-3">
    <!-- title -->
    <div class="my-6 d-flex flex-column text-center text-sm-left">
      <span
        class="text-h5 secondary--text"
        v-text="'Fondos para compras por emergencia'"
      />
    </div>
    <!-- body -->
    <v-row>
      <v-col v-if="!haveRole(['ROLE_READ_COMPRA_POR_EMERGENCIA'])" cols="12" class="d-flex justify-center justify-sm-end">
        <v-btn
          class="button-extra text-no-style mt-4 mt-sm-0 mx-8 mx-sm-4"
          color="secondary"
          @click="
            (show_modal_detail_var = true),
              $refs.monto_fondo_var?.updateValue(''),
              tab = 0
          "
        >
          Agregar
        </v-btn>
      </v-col>
      <v-col cols="12">
        <span
          class="text--disabled text-body-2 pl-2"
          v-text="'Listado de fondos'"
        />
        <!--  componente dinamico global de tabla con paginacion -->
        <data-table-component
          :actions="ACTIONS_CONST"
          :headers="HEADERS_CONST"
          :items="list_fondos_var"
          :show_actions="true"
          :total_registros="total_rows_var"
          @editFondoEvent="OpenModalDetailFtn"
          @paginar="PaginateFtn"
          v-models:pagina="pagination_var.page"
          v-models:select="pagination_var.per_page"
        >
          <template v-slot:[`item.monto_establecido`]="{ item }">
            {{
              Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(item.monto_establecido)
            }}
          </template>
          <template v-slot:[`item.monto_disponible`]="{ item }">
            {{
              Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(item.monto_disponible)
            }}
          </template>
          <template v-slot:[`item.fecha_creacion`]="{ item }">
            {{ moment(item.created_at).format("DD/MM/YYYY hh:mm A") }}
          </template>
          <template v-slot:[`item.fecha_actualizacion`]="{ item }">
            {{  moment(item.updated_at).format("DD/MM/YYYY hh:mm A") }}
          </template>
        </data-table-component>
      </v-col>
    </v-row>

    <v-dialog v-model="show_modal_detail_var" persistent max-width="800">
      <v-card>
        <div class="headerIcon">
          <v-icon class="secondary--text" @click="CloseModalDetailFtn">
            mdi-close
          </v-icon>
        </div>
        <v-card-text>
          <!-- modal -->
        <section>
          <v-row>
            <v-col>
              <v-tabs v-model="tab">
                <v-tab v-for="(item, index) in tabs" :key="index">
                  {{ item.nombre }}
                </v-tab>
                <v-tabs-slider color="secondary" />
              </v-tabs>
            </v-col>
          </v-row>
            <v-row>
              <v-col>
                <v-tabs-items v-model="tab">
                  <v-tab-item>
                    <br />
                    <v-row class="mx-sm-6" no-gutters>
                      <!-- registrar fondo -->
                      <v-row class="mx-sm-6">
                        <v-col cols="12">
                          <span class="text-h6 secondary--text">Registrar fondo</span>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="Nombre *"
                            outlined
                            v-model="nombre_fondo_var"
                            @input="$v.nombre_fondo_var.$touch()"
                            @blur="$v.nombre_fondo_var.$touch()"
                            :error-messages="nombreFondoError"
                            clearable
                            maxlength="75"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <money-text-field-component
                            label="Monto asignado *"
                            v-model="monto_fondo_var"
                            ref="monto_fondo_var"
                            @blur="$v.monto_fondo_var.$touch"
                            :error-messages="montoFondoError"
                          />
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-select
                            v-model="anio_fiscal"
                            :items="anios_fiscales"
                            item-text="anio"
                            item-value="id"
                            label="Año fiscal"
                            outlined
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          class="d-flex justify-center justify-sm-end"
                          v-if="false"
                        >
                        </v-col>
                        <v-col cols="12">
                          <v-textarea
                            label="Motivo *"
                            outlined
                            rows="4"
                            auto-grow
                            v-model="motivo_fondo_var"
                            @input="$v.motivo_fondo_var.$touch()"
                            @blur="$v.motivo_fondo_var.$touch()"
                            :error-messages="motivoFondoError"
                            clearable
                            maxlength="250"
                          />
                        </v-col>


                        <v-col cols="12" sm="6">
                          <v-menu
                            v-model="show_date_picker_var_inicio"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                          >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="formatearFecha(fecha_inicio)"
                              label="Fecha de inicio *"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                              clearable
                              @input="$v.fecha_inicio.$touch"
                              :error-messages="fechaInicioError"
                            />
                          </template>
                          <v-date-picker
                            v-model="fecha_inicio"
                            type="date"
                            color="secondary"
                            locale="es"
                            @input="onDateChangeFechaIni"
                            />
                          </v-menu>
                        </v-col>

                        <v-col cols="12" sm="6">
                          <v-menu
                            v-model="show_date_picker_var_fin"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                          >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="formatearFecha(fecha_fin)"
                              label="Fecha de fin *"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                              clearable
                              @input="$v.fecha_fin.$touch"
                              :error-messages="fechaFinError"
                            />
                          </template>
                          <v-date-picker
                            v-model="fecha_fin"
                            type="date"
                            color="secondary"
                            locale="es"
                            @input="onDateChangeFechaFin"
                            />
                          </v-menu>
                        </v-col>
                      </v-row>

                      <!-- administrar fondo -->
                      <v-row class="mx-sm-6">
                        <v-col cols="12" class="pt-0">
                          <span class="text-h6 secondary--text">Administrador del fondo</span>
                        </v-col>
                        <v-col cols="12" sm="7">
                          <v-row>
                            <v-col cols="10">
                              <v-text-field
                                label="Correo Electrónico *"
                                outlined
                                color="secondary"
                                v-model="email_admin_var"
                                @input="$v.email_admin_var.$touch()"
                                @blur="$v.email_admin_var.$touch()"
                                :error-messages="emailAdminError"
                                clearable
                              />
                            </v-col>
                            <v-col cols="2" class="text-center">
                              <v-btn
                                :disabled="email_admin_var == null"
                                icon
                                class="mt-1"
                                x-large
                                @click="handlerAddFtn"
                              >
                                <v-icon color="secondary" large> mdi-plus-box</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12">
                          <DataTableComponent
                            :headers="HEADERS_ADM"
                            :items="stack_emails_var"
                            :tiene_paginacion="false"
                            >
                            <template v-slot:[`item.actions`]="{ item }">
                              <v-btn icon medium @click="DeleteAdminFondoFtn(item.id)">
                                <v-icon color="error"> mdi-delete </v-icon>
                              </v-btn>
                            </template>
                          </DataTableComponent>
                        </v-col>
                      </v-row>
                    </v-row>
                    <br />
                  </v-tab-item>

                  <v-tab-item>
                    <br />
                    <v-row>
                      <v-col cols="12" ms="4">
                        <v-text-field
                          v-model="nombre_doc"
                          label="Nombre de adjunto *"
                          outlined
                            @input="$v.nombre_doc.$touch()"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="id_tipo_documento"
                          label="Tipo de documento"
                          outlined
                          :items="catalogoDocumentos"
                          return-object
                          item-text="nombre"
                          @input="$v.id_tipo_documento.$touch()"
                        >
                          <template v-slot:item="{ item }">
                            <v-icon>{{ item.requerido ? "mdi-check" : "" }}</v-icon>
                            &nbsp &nbsp &nbsp
                            {{ item.nombre }}
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" ms="4">
                        <v-file-input
                          v-model="documento_doc"
                          label="Subir documentos"
                          multiple
                          outlined
                          accept="application/pdf"
                          show-size
                          counter
                          clearable
                          @input="$v.documento_doc.$touch()"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="12" ms="4">
                        <v-btn @click="agregarDocumento">Agregar documento</v-btn>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <data-table-component
                          :inhabilitar_paginacion="true"
                          :tiene_paginacion="false"
                          :headers="headers_documentos"
                          :items="documentos"
                        >
                          <template v-slot:item.documento="{ item }">
                            {{ item.documento?.name }}
                          </template>
                          <template v-slot:item.tipo="{ item }">
                            {{ item.id_tipo_doc?.nombre }}
                          </template>
                          <template v-slot:item.acciones="{ item }">
                            <v-icon
                              :color="accion.color"
                              class="mr-2"
                              v-for="(accion, index) in item.acciones"
                              :key="index"
                              @click="accion.callback(item)"
                            >
                              {{ accion.icon }}
                            </v-icon>
                          </template>
                        </data-table-component>
                      </v-col>
                    </v-row>
                    <br />
                    <br />
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </section>
          <v-row>
            <v-col>
              <v-btn
                class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
                min-width="30%"
                color="secondary"
                outlined
                @click="CloseModalDetailFtn"
              >
                Cancelar
              </v-btn>
              <v-btn
                class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
                min-width="30%"
                color="secondary"
                @click="handlerSaveFtn"
              >
              {{ id_fondo_selected_var ? "Actualizar" : "Registrar" }}
            </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <PdfModal
      :isOpen="modalVisualizacionAdjuntoAbierta" 
      :source-loadable="visualizacionAdjunto" 
      @on-visibility-change="manejarVisibilidadModalVisualizacionAdjunto"
      filename="visualizacion-registro-compra"
    />

    <ConfirmationModalComponent 
			:isOpen="modalConfirmacionEliminar" 
			description="¿Desea continuar con la eliminación del registro?"
			@confirm="eliminarDocumentoEnBase" 
			@cancel="cancelarEliminacion" 
			:isLoading="deleteAction.isLoading" 
    />
  </section>
  
</template>
<script>
import { createLoadable, toggleLoadable, setLoadableResponse } from '@/utils/loadable.js';
import DataTableComponent from "@/components/DataTableComponent.vue";
import MoneyTextFieldComponent from "@/components/MoneyTextFieldComponent.vue";
import { helpers, required, requiredIf } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import { convertToFormData } from "@/utils/data";
import PdfModal from '@/components/PdfModal.vue';
import ConfirmationModalComponent from '@/components/utils/ConfirmationModalComponent.vue';
import { pick } from 'lodash';
import moment from 'moment';


const email = helpers.regex('email', /^[a-zA-Z\d]+[a-zA-Z\d._-]*@([a-zA-Z\d]+(-|.)?){0,3}[a-zA-Z\d]+(\.[a-zA-Z\d]+)?(\.[a-zA-Z\d]{2,})$/);
const txtField = helpers.regex("txtField", /^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ0-9 -]*$/);
const txtArea = helpers.regex(
  "txtArea",
  /^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ0-9 ()/.,\n:;-]*$/
);

export default {
  name: "fondosCompraPorEmergencia",
  components: {
    DataTableComponent,
    MoneyTextFieldComponent,
    PdfModal,
    ConfirmationModalComponent,
  },
  validations: {
    nombre_fondo_var: {
      required,
      txtField,
    },
    monto_fondo_var: {
      required,
    },
    id_tipo_documento: {
      required,
    },
    nombre_doc: {
      required,
    },
    documento_doc: {
      required,
    },
    motivo_fondo_var: {
      required,
      txtArea,
    },
    email_admin_var: {
      // requerido si el stack de fondos esta vacio
      requiredIf: requiredIf(function (inst) {
        return inst?.stack_emails_var?.length === 0;
      }),
      email,
    },
    fecha_inicio: {
      required,
    },
    fecha_fin: {
      required,
    },
  },
  data: () => ({
    anio_fiscal: null,
    anios_fiscales: [],
    /*
     * WARNING: este array es parte de una props para el componente DataTableComponent,
     * por lo que no se debe modificar su estructura, si desea agregar condiciones especiales,
     * favor de crear una funcion del tipo init para cargar las acciones, o simplemente
     * trabajar con slots
     */
    ACTIONS_CONST: [
      {
        tooltip: {
          text: "Editar fondo",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-pencil",
        },
        eventName: "editFondoEvent",
      },
    ],

    SNAPSHOT_CONST: {},

    // variables
    list_fondos_var: [],
    pagination_var: {
      page: 1,
      per_page: 10,
    },
    total_rows_var: 0,

    stack_emails_var: [],
    show_modal_detail_var: false,
    id_fondo_selected_var: null,

    // v -> modal
    nombre_fondo_var: null,
    monto_fondo_var: null,
    estado_fondo_var: 1, // 1 = habilitado, 2 = deshabilitado
    motivo_fondo_var: null,
    email_admin_var: null,
    documento_doc: null,
    fecha_inicio: null,
    fecha_fin: null,
    show_date_picker_var_inicio: false,
    show_date_picker_var_fin: false,
    id_tipo_documento: null,
    nombre_doc: null,
    catalogoDocumentos: [],
    documentos: [],
    DataEliminarDocumentoEnBase: null,
    // pestañas
    tab: 0,
    tabs: [
      {
        nombre: "Registrar fondo",
      },
      {
        nombre: "Documentos iniciales",
      },
    ],
    // documentos
    headers_documentos: [
      {
        text: "Documento",
        align: "start",
        value: "documento",
      },
      {
        text: "Nombre de documento",
        align: "center",
        value: "nombre_doc",
      },
      {
        text: "Tipo de documento",
        align: "center",
        value: "tipo",
      },
      {
        text: "Acciones",
        align: "center",
        value: "acciones",
        sortable: false,
      },
    ],
    //visualizar Adjunto
		visualizacionAdjunto: createLoadable(null),
		modalVerAdjunto: false,
		modalVisualizacionAdjuntoAbierta: false,
    //eliminar adjunto
    modalConfirmacionEliminar: false,
    deleteAction: createLoadable(null),
    
  }),
  computed: {
    // mapeo de estados
    ...mapState({
      id_institucion_cmp: (state) => state?.selectedUnidad?.instituciones?.id,
    }),

    // errores de validacion
    nombreFondoError() {
      const errors = [];
      if (!this.$v.nombre_fondo_var.$dirty) return errors;
      !this.$v.nombre_fondo_var.required &&
        errors.push("El nombre es requerido");
      !this.$v.nombre_fondo_var.txtField &&
        errors.push("El nombre no es válido");
      return errors;
    },
    montoFondoError() {
      const errors = [];
      if (!this.$v.monto_fondo_var.$dirty) return errors;
      !this.$v.monto_fondo_var.required &&
        errors.push("El monto asignado es requerido");
      return errors;
    },
    motivoFondoError() {
      const errors = [];
      if (!this.$v.motivo_fondo_var.$dirty) return errors;
      !this.$v.motivo_fondo_var.required &&
        errors.push("El motivo es requerido");
      !this.$v.motivo_fondo_var.txtArea &&
        errors.push("El motivo no es válido");
      return errors;
    },
    emailAdminError() {
      const errors = [];
      if (!this.$v.email_admin_var.$dirty) return errors;
      !this.$v.email_admin_var.requiredIf &&
      errors.push("El correo electrónico es requerido");
      !this.$v.email_admin_var.email &&
        errors.push("Ingresa un correo electrónico con formato válido");
      return errors;
    },
    fechaInicioError() {
      const errors = [];
      if (!this.$v.fecha_inicio.$dirty) return errors;
      !this.$v.fecha_inicio.required &&
        errors.push("La fecha de inicio es requerido");
      return errors;
    },
    fechaFinError() {
      const errors = [];
      if (!this.$v.fecha_fin.$dirty) return errors;
      !this.$v.fecha_fin.required &&
        errors.push("La fecha de fin es requerido");
      return errors;
    },
    HEADERS_CONST() {
      const headers = [
        {
          align: "center",
          text: "Correlativo",
          value: "correlativo",
        },
        {
          align: "center",
          text: "Nombre",
          value: "nombre",
        },
        {
          align: "center",
          text: "Monto asignado ($)",
          value: "monto_establecido",
        },
        {
          align: "center",
          text: "Monto disponible ($)",
          value: "monto_disponible",
        },
        {
          align: "center",
          text: "Fecha de creación",
          value: "fecha_creacion",
        },
        {
          align: "center",
          text: "Fecha de actualización",
          value: "fecha_actualizacion",
        },
      ]
      if(!this.haveRole(['ROLE_READ_COMPRA_POR_EMERGENCIA'])){
        headers.push({
          align: "center",
          text: "Acciones",
          value: "actions",
          sortable: false,
        })      
      }
      return headers
    },
    HEADERS_ADM() {
      const headers = [
        {
          align: "center",
          text: "Nombre Empleado",
          value: "nombre",
          sortable: false,
        },
        {
          align: "center",
          text: "Email",
          value: "email",
          sortable: false,
        },
      ]
      if(this.id_fondo_selected_var){
        headers.push({
          align: "center",
          text: "Acciones",
          value: "actions",
          sortable: false,
        })
      }
      return headers
    },
    docsRequeridosFaltantes() {
      return this.catalogoDocumentos.filter(
        (doc) =>
          !this.documentos.find((d) => d.id_tipo_doc.id === doc.id) &&
          doc.requerido
      );
    },
    docsRequeridosFaltantesTexto() {
      return this.docsRequeridosFaltantes.reduce(
        (prev, next) => `${prev} ${next.nombre}. `,
        ""
      );
    },
    minimumMonthToReport() {
      return this.minDateToReport;
    },
  },
  methods: {
    // Funciones regulares
    onDateChangeFechaIni(date) {
      this.fecha_inicio = date;
      this.show_date_picker_var_inicio = false;
    },
    onDateChangeFechaFin(date) {
      this.fecha_fin = date;
      this.show_date_picker_var_fin = false;
    },
    formatearFecha(fecha) {
      return fecha ? moment(fecha).format('DD/MM/YYYY') : null;
    },

    /**
     *
     * @description funcion para limpiar los inputs del modal
     */
    CleanInputsFtn() {
      this.nombre_fondo_var = null;
      this.motivo_fondo_var = null;
      this.email_admin_var = null;
      this.monto_fondo_var = null;
      this.fecha_inicio = null;
      this.fecha_fin = null;
      this.id_tipo_documento = null;
      this.documento_doc = null;
      this.nombre_doc = null;
      this.documentos = [];
      this.stack_emails_var = [];
      this.anio_fiscal = null;
      this.tab = 0;
    },

    /**
     *
     * @description funcion para cerrar el modal de detalle
     */
    async CloseModalDetailFtn() {
      if(!this.id_fondo_selected_var){
        this.show_modal_detail_var = false;
        this.id_fondo_selected_var = null;
        this.SNAPSHOT_CONST = {};
        this.$refs.monto_fondo_var?.updateValue("");
        this.CleanInputsFtn();
      }else{
        const [documentosCargados, nombre] = await this.verificarDocumentosRequeridos();

        if (!documentosCargados){
          this.temporalAlert({
              message: `No se cargó el documento ${nombre}`,
              show: true,
              type: "warning",
            });
          this.tab = 1;
          return;
        }
        this.show_modal_detail_var = false;
        this.id_fondo_selected_var = null;
        this.SNAPSHOT_CONST = {};
        this.$refs.monto_fondo_var?.updateValue("");
        this.CleanInputsFtn();
      }
    },

    /**
     *
     * @description funcion para abrir el modal de detalle
     * @param {Object} item
     */
    OpenModalDetailFtn(item) {
      this.show_modal_detail_var = true;
      this.id_fondo_selected_var = Number(item.id);
      this.FetchFondoByIdFtn();
    },

    /**
     *
     * @description funcion para validar si un administrador ya esta en el stack
     */
    verifyStackFtn() {
      // verificar si el empleado ya esta en el stack
      const check_repeat_sc_var = this.stack_emails_var.find(
        (item) => item.email == this.email_admin_var
      );

      if (check_repeat_sc_var) {
        this.temporalAlert({
          message: "Empleado ya agregado",
          show: true,
          type: "warning",
        });
        this.email_admin_var = null;
        return false;
      } else {
        return true;
      }
    },

    /**
     *
     * @description funcion tipo handler para las acciones del boton de guardar
     */
    handlerSaveFtn() {
      if (this.id_fondo_selected_var) {
        this.UpdateFondoFtn();
      } else {
        this.CreateFondoFtn();
      }
    },

    /**
     *
     * @description funcion tipo handler para las acciones del boton de agregar
     */
    handlerAddFtn() {
      if (this.id_fondo_selected_var) {
        this.AddAdminFondoFtn();
      } else {
        this.FetchAdminFondoFtn();
      }
    },
    async AddAdminFondoFtn() {
      if (this.$v.email_admin_var.$invalid) {
        this.$v.email_admin_var.$touch();
        return;
      } else {
        if (!this.verifyStackFtn()) return;

        const INFO_SC_CONST = await this.services.CompraPorEmergencia.getEmpleadoByEmail(
            { email: this.email_admin_var }
          );

        if (INFO_SC_CONST.status === 200) {
          const RESP_SC_CONST =
            await this.services.CompraPorEmergencia.postAddAdminFondo(
              this.id_fondo_selected_var,
              INFO_SC_CONST.data.id
            );

          if (RESP_SC_CONST.status === 201) {
            const { data } = RESP_SC_CONST;
            this.temporalAlert({
              message: "Empleado agregado",
              show: true,
              type: "success",
            });
            this.stack_emails_var.push({
              id: data.id,
              email: data.email,
              nombre: data.nombre,
            });
            this.email_admin_var = null;
          }
        } else {
          this.temporalAlert({
            message: "Empleado no encontrado",
            show: true,
            type: "error",
          });
        }
      }
    },

    /**
     *
     * @description funcion para eliminar del fondo ( API )
     * @param {Number} id_admin_param
     */
    async DeleteAdminFondoFtn(id_admin_param) {
      if (this.stack_emails_var.length === 1) {
        this.temporalAlert({
          message: "El fondo debe tener al menos un administrador",
          show: true,
          type: "warning",
        });
        return;
      }

      const RESP_SC_CONST = await this.services.CompraPorEmergencia.deleteAdminFondo(
        // this.id_fondo_selected_var,
        id_admin_param
      );

      if (RESP_SC_CONST.status === 204) {
        this.temporalAlert({
          message: "Administrador eliminado",
          show: true,
          type: "success",
        });
        this.stack_emails_var = this.stack_emails_var.filter(
          (item) => item.id !== id_admin_param
        );
      } else {
        this.temporalAlert({
          message: "Administrador no eliminado",
          show: true,
          type: "error",
        });
      }
    },

    /**
     *
     * @description funcion para obtener la informacion de un empleado por su email ( API )
     */
    async FetchAdminFondoFtn() {
      if (this.$v.email_admin_var.$invalid) {
        this.$v.email_admin_var.$touch();
        return;
      } else {
        if (!this.verifyStackFtn()) return;

        const RESP_SC_CONST =
          await this.services.CompraPorEmergencia.getEmpleadoByEmail(
            { email: this.email_admin_var }
          );

        if (RESP_SC_CONST.status === 200) {
          this.temporalAlert({
            message: "Empleado encontrado",
            show: true,
            type: "success",
          });
          this.stack_emails_var.push(RESP_SC_CONST.data);
        } else {
          this.temporalAlert({
            message: "Empleado no encontrado",
            show: true,
            type: "error",
          });
        }
        this.email_admin_var = null;
      }
    },

    // <-- Fondos -->
    /**
     *
     * @description funcion para crear un fondo ( API )
     */
    async CreateFondoFtn() {
      if (
        this.$v.nombre_fondo_var.$invalid ||
        this.$v.motivo_fondo_var.$invalid ||
        this.$v.monto_fondo_var.$invalid ||
        this.$v.fecha_inicio.$invalid ||
        this.$v.fecha_fin.$invalid
      ) {
        this.$v.$touch();
        this.temporalAlert({
          message: "Los campos marcados son requeridos",
          show: true,
          type: "warning",
        });
        return;
      } else {
        if (this.documentos.length === 0) {
          this.temporalAlert({
            message: "Adjunte los documentos requeridos",
            show: true,
            type: "warning",
          });
          this.tab = 1;
          return;
        } else if (this.stack_emails_var == 0) {
          this.temporalAlert({
            message: "Debe agregar al menos un administrador",
            show: true,
            type: "warning",
          });
          return;
        }

        const RESP_SC_CONST = await this.services.CompraPorEmergencia.postAddFondo(
          convertToFormData({
            nombre: this.nombre_fondo_var,
            // estado: this.estado_fondo_var,
            id_institucion: this.id_institucion_cmp,
            motivo: this.motivo_fondo_var,
            monto_establecido: Number(this.monto_fondo_var),
            administradores: this.stack_emails_var.map((item) => item.id),
            id_anio_fiscal: this.anio_fiscal,
            fecha_hora_inicio: this.fecha_inicio,
            fecha_hora_fin: this.fecha_fin,
            documentos: this.documentos,
          })
        );

        if (RESP_SC_CONST.status === 201) {
          this.temporalAlert({
            message: "Fondo creado correctamente",
            show: true,
            type: "success",
          });
          this.FetchListFondosFtn(true);
        } else {
          this.temporalAlert({
            message: "Error al crear el fondo",
            show: true,
            type: "error",
          });
        }
        this.CloseModalDetailFtn();
      }
    },

    /**
     *
     * @description funcion para actualizar un fondo ( API )
     */
    async UpdateFondoFtn() {
      if (
        this.$v.nombre_fondo_var.$invalid ||
        this.$v.motivo_fondo_var.$invalid ||
        this.$v.monto_fondo_var.$invalid ||
        this.$v.fecha_inicio.$invalid ||
        this.$v.fecha_fin.$invalid
      ) {
        this.$v.$touch();
        this.temporalAlert({
          message: "Los campos marcados son requeridos",
          show: true,
          type: "warning",
        });
        return;
      }
      
      const [documentosCargados, nombre] = await this.verificarDocumentosRequeridos();

      if (!documentosCargados){
        this.temporalAlert({
            message: `No se cargó el documento ${nombre}`,
            show: true,
            type: "warning",
          });
        this.tab = 1;
        return;
      }
        const nuevosDocumentos = this.documentos.filter(documento => !documento.existente);
        const BODY_ARGM_SC_CONST = {
          nombre: this.nombre_fondo_var,
          // estado: this.estado_fondo_var,
          motivo: this.motivo_fondo_var,
          monto_asignado: Number(this.monto_fondo_var),
          documentos: nuevosDocumentos.map((documento) => documento.documento),
          documentos_data: nuevosDocumentos.map((documento) => pick(documento, ['nombre_doc', 'id_tipo_doc'])),
          administradores: this.stack_emails_var.map((item) => item.id),
          fecha_hora_inicio: this.fecha_inicio,
          fecha_hora_fin: this.fecha_fin,
        };


        // let check_similar_sc_var =
        //   JSON.stringify(this.SNAPSHOT_CONST) ===
        //   JSON.stringify(BODY_ARGM_SC_CONST);

        // if (check_similar_sc_var) {
        //   this.temporalAlert({
        //     message: "No se han realizado cambios",
        //     show: true,
        //     type: "info",
        //   });
        //   return;
        // } else {

        const RESP_SC_CONST = await this.services.CompraPorEmergencia.putEditFondo(
          this.id_fondo_selected_var,
          convertToFormData(BODY_ARGM_SC_CONST),
        );



        if (RESP_SC_CONST.status === 200) {
          this.temporalAlert({
            message: "Fondo actualizado correctamente",
            show: true,
            type: "success",
          });
          this.FetchListFondosFtn();
        } else {
          this.temporalAlert({
            message: "Error al actualizar el fondo",
            show: true,
            type: "error",
          });
        }
        this.CloseModalDetailFtn();
      // }
      // }
    },

    async verificarDocumentosRequeridos(){
      await this.obtenerCatalogoDocumentos();
      const ctlDocumentos = this.catalogoDocumentos;
      const documentosRequeridos = ctlDocumentos.filter(documento => documento.requerido);
      let nombreDocumento = null;

      const documentosCargados = documentosRequeridos.every(documento => {
        const existeDocumento = this.documentos.some(
          (docFilt) => Number(docFilt.id_tipo_doc.id) === Number(documento.id)
        );
        if (!existeDocumento) {
          nombreDocumento = documento.nombre;
        }
        return !!existeDocumento;
      });
      return [documentosCargados, nombreDocumento];
    },

    eliminarDocumentoEnCache(documentoEliminar) {
      this.documentos = this.documentos.filter((doc) => doc.documento !== documentoEliminar.documento);
    },

    cancelarEliminacion() {
      this.modalConfirmacionEliminar = false;
    },

    abrirModalEliminarDocumentoEnBase(documentoEnBase) {
      this.modalConfirmacionEliminar = true;
      this.DataEliminarDocumentoEnBase = documentoEnBase;
    },

    async eliminarDocumentoEnBase() {
			const idFondoCompra = this.DataEliminarDocumentoEnBase.id;
      toggleLoadable(this.deleteAction);
      const { data } = await this.services.CompraPorEmergencia.deleteAdjuntoFondoCompraEmergencia(idFondoCompra);
      setLoadableResponse(this.deleteAction, data, { showAlertOnSuccess: true, skipOnSuccess: true });
      this.modalConfirmacionEliminar = false;
      this.FetchFondoByIdFtn();
    },

    manejarVisibilidadModalVisualizacionAdjunto(visible) {
			this.modalVisualizacionAdjuntoAbierta = visible;
		},

    async visualizarDocumento(documento) {
			this.modalVisualizacionAdjuntoAbierta = true;
			const idFondoCompra = documento.id;
			toggleLoadable(this.visualizacionAdjunto);
			const { data } = await this.services.CompraPorEmergencia.visualizarAdjuntoFondoCompraEmergencia(idFondoCompra);
			setLoadableResponse(this.visualizacionAdjunto, data, { isFile: true });
		},

    /**
     *
     * @description funcion para obtener un fondo por su id ( API )
     */
    async FetchFondoByIdFtn() {
      if (this.id_fondo_selected_var) {
        const RESP_SC_CONST = await this.services.CompraPorEmergencia.getFondoById(
          this.id_fondo_selected_var
        );

        if (RESP_SC_CONST.status === 200) {
          const { data } = RESP_SC_CONST;

          const fechaInicio = moment(data.fecha_hora_inicio);
          const fechaFin = moment(data.fecha_hora_fin);

          this.nombre_fondo_var = data.nombre;
          this.estado_fondo_var = data.estado;
          this.fecha_inicio = fechaInicio.isValid() ? fechaInicio.format('YYYY-MM-DD') : null;
          this.fecha_fin = fechaFin.isValid() ? fechaFin.format('YYYY-MM-DD') : null;
          this.anio_fiscal = data.id_anio_fiscal;

          this.motivo_fondo_var = data.motivo;
          this.stack_emails_var = data.administradores;
          this.$refs.monto_fondo_var.updateValue(
            Number(data.monto_establecido)
          );
          
          this.documentos = [];
          data.DocumentoFondoCompraEmergencia.map(documentoGuardado => {
            this.documentos.push({
              existente: true,
              documento: documentoGuardado.documento,
              acciones: [
              {
                  icon: "mdi-eye",
                  color: 'primary',
                  tooltip: "Ver",
                  callback: () => this.visualizarDocumento(documentoGuardado),
                },
                {
                  icon: "mdi-delete",
                  color: 'red',
                  tooltip: "Eliminar",
                  callback: () => this.abrirModalEliminarDocumentoEnBase(documentoGuardado),
                },
              ],
              id_tipo_doc: documentoGuardado.cdce,
              nombre_doc: documentoGuardado.nombre_documento,
            });
          })
        

          this.SNAPSHOT_CONST = {
            nombre: data.nombre,
            estado: data.estado,
            motivo: data.motivo,
            monto_asignado: Number(data.monto_establecido),
            administradores: data.administradores.map((item) => item.id),
          };
        }
      }
    },

    /**
     *
     * @description funcion para paginar la tabla de fondos ( API )
     * @param {Object} filters_param
     */
    async PaginateFtn(filters_param) {
      const { cantidad_por_pagina, pagina } = filters_param;

      this.pagination_var.per_page = cantidad_por_pagina;
      this.pagination_var.page = pagina;

      this.FetchListFondosFtn();
    },

    /**
     *
     * @description funcion para obtener la lista de fondos ( API )
     * @param {Boolean} init_param
     */
    async FetchListFondosFtn(init_param = false) {
      if (init_param) {
        this.pagination_var.page = 1;
        this.pagination_var.per_page = 10;
      }

      const filters_sc_var = {
        page: this.pagination_var.page,
        per_page: this.pagination_var.per_page,
      };

      const RESP_SC_CONST = await this.services.CompraPorEmergencia.getListFondos(
        filters_sc_var
      );

      if (RESP_SC_CONST.status === 200) {
        const { data, headers } = RESP_SC_CONST;
        this.list_fondos_var = data;
        
        this.pagination_var.page = Number(headers.page);
        this.pagination_var.per_page = Number(headers.per_page);
        this.total_rows_var = Number(headers.total_rows);

        this.list_fondos_var.forEach((item, index) => {
          item.correlativo = index + 1;
        });
      }
    },
    async obtenerAniosFiscales() {
      const { data } = await this.services.PacServices.listadoAniosFiscales();
      this.anios_fiscales = data;
    },

    async agregarDocumento() {
      if (this.documento_doc && this.id_tipo_documento && this.nombre_doc) {

        this.documentos.push({
          documento: this.documento_doc[0],
          acciones: [
            {
              icon: "mdi-delete",
              color: 'red',
              tooltip: "Eliminar",
              callback:this.eliminarDocumentoEnCache,
            },
          ],
          id_tipo_doc: this.id_tipo_documento,
          nombre_doc: this.nombre_doc,
        });
        this.nombre_doc = null;
          this.documento_doc= null;
          this.id_tipo_documento= null;
        return;
      }
      if (!this.documento_doc)
        this.pushAppMessage({
          message: "Debe seleccionar un archivo",
          type: "info",
        });
      if (!this.id_tipo_documento)
        this.pushAppMessage({
          message: "Debe seleccionar un tipo de documento",
          type: "info",
        });
      if (!this.nombre_doc)
        this.pushAppMessage({
          message: "Debe colocar un nombre de documento",
          type: "info",
        });
    },

    async obtenerCatalogoDocumentos() {
      const { data } =
        await this.services.CompraPorEmergencia.cargarTiposDocumentosCompraEmergencia();
      this.catalogoDocumentos = data;
    },
  },
  watch: {
    show_modal_detail_var(value) {
      if (value) return;

      this.$v.$reset();
    },
    // fecha_inicio(value) {
    //   console.log('value :>> ', value);
    // }
  },
  created() {
    this.FetchListFondosFtn(true);
    this.obtenerAniosFiscales();
    this.obtenerCatalogoDocumentos();
  },
};
</script>
